
import { defineComponent, onMounted, ref, watch } from 'vue';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import useUtils from '@/mixins/useUtils';

export default defineComponent({
  name: 'BidListTable',
  props: {
    productDetail: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const bidList = ref<Array<any>>([]);
    const { maskingId } = useUtils();

    const fetchBidList = async () => {
      try {
        const { data } = await partnerAPI.commonLot.bidderListUsingGET({
          productId: props.productDetail.productId,
        });
        console.log(data as any);
        bidList.value = (data as any).data.sort((p, n) =>
          new Date(p.createdAt).getTime() < new Date(n.createdAt).getTime()
            ? 1
            : -1
        );
      } catch (e) {
        console.error(e);
      }
    };

    watch(
      () => bidList.value,
      (newBidList) => {
        const DEFAULT = 18;
        if (newBidList && newBidList.length < DEFAULT) {
          const dummy = [...Array(DEFAULT - newBidList.length).keys()].map(
            () => {
              return {};
            }
          );
          bidList.value = bidList.value.concat(dummy);
        }
      }
    );

    onMounted(() => {
      fetchBidList();
    });

    const formatDate = (date: string) => {
      return date && format(new Date(date), 'yyyy-MM-dd / HH:mm:ss');
    };

    const formatNumber = (number) => {
      return number && number.toLocaleString();
    };

    return {
      maskingId,
      bidList,
      formatDate,
      formatNumber,
    };
  },
});
